import React, { useState, useEffect, useContext } from "react";
import { getMyServices } from "../api";
import Loading from '../components/Loading';
import * as bootstrap from 'bootstrap';
import ToastModal from "../components/ToastModal";
import $ from 'jquery';
import UserContext from "../context/useLoginContext";
import { useNavigate } from "react-router-dom";
import pencil from '@img/pencil.webp';
import notStoreService from '@img/icon_not_store_services.webp';
import placeholder from '@img/placeholder.webp';
import '@styles/Services.scss';

const Services = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [isdashPage, setIsdashPage] = useState(true);
    const [storesList, setStoreslist] = useState([]);
    const [messageToast, setMessageToast] = useState(null);
    const [finish, setFinish] = useState(false);
    const [hasPermitions, setHasPermitions] = useState(false);
    const { user } = useContext(UserContext);

    const handleScroll = () => {
        const container = document.getElementById('contentDetail');
        if (!container) return;
        const scrollHeight = container.scrollHeight;
        const offsetHeight = container.offsetHeight;
        const scrollTop = container.scrollTop;
        if( scrollTop === (scrollHeight -offsetHeight) && !finish) {
            setIsdashPage(false)
            processDataSearch(page);
        }
    };

    useEffect(() => {
        const container = document.getElementById('contentDetail');
        if (!container) return;
        container.addEventListener('scroll', handleScroll);
        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, [user, page]);

    const fetchData = async () => {
        try {
            if (user) {
                await processDataSearch(page);
            }
        } catch (error) {
            console.error("Error fetching store when user is logged:", error);
            setMessageToast("¡Lo sentimos! En este momento no hay establecimientos disponibles para la busqueda seleccionada.")
            if (messageToast) {
                const toastEl = $('#liveToast')[0];
                if (toastEl) {
                    let toast = new bootstrap.Toast(toastEl, { delay: 5000 });
                    toast.show();
                }
            }
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, [user]);

    const processDataSearch = async(page) =>{
        setLoading(true);
        const response = await getMyServices({page});
        if(response?.data?.stores.length===0){
            setFinish(true)
        }
        setHasPermitions(response?.hasPermitions)
        setStoreslist([...storesList, ...response?.data?.stores]);
        setPage(page+1)
        setLoading(false);
        setIsdashPage(true)
    }

    const viewDetail = async(id) =>{
        navigate(`/dashboard/store-${id}`)
    }

    return (
        <div className="Services">
            <div className='Content'>
                <div className="title">Mis servicios</div>
                {
                    storesList.length> 0 &&
                        <div className="add-service" onClick={()=> navigate('/dashboard/new-service')}>
                            Agregar Servicio
                        </div>
                }
                <div className="content-detail" id="contentDetail">
                    {
                        storesList.length> 0 ?
                        <div className="list-services">
                            <div>
                                {
                                    storesList.map((item, index) => (
                                        <div className={`Card`} key={index}>
                                            <div className='image'>
                                                <img src={item?.image || placeholder} alt="firullife-img" onError={(e) => { e.target.onerror = null; e.target.src = placeholder; }} />
                                            </div>
                                            <div className="detail">
                                                <div className = "detail-text">
                                                    <p className="name">{item?.name}</p>
                                                    {(item?.isValidated || hasPermitions) && <img src={pencil} alt="firullife-img" onClick={()=>viewDetail(item?.id)}/>}
                                                    <p className="reference">tipo de establecimiento: {item?.type}</p>
                                                    <p className="reference">Está disponible:<span className={item?.isAvailable ? "istrue": "isfalse"}>{item?.isAvailable ? "Si" : "No"}</span></p>
                                                    <p className="reference">Está validado:<span className={item?.isValidated ? "istrue": "isfalse"}>{item?.isValidated ? "Si" : "Pendiente"}</span></p>
                                                    <div className="status"></div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>:
                        <div className="not-services">
                            <img src={notStoreService} alt="firullife-img" onClick={()=>viewDetail(item?.id)}/>
                            <p>No tienes ningún servicio registrado</p>
                            <div className="add-service" onClick={()=> navigate('/dashboard/new-service')}>
                                Agregar Servicio
                            </div>
                        </div>
                    }
                </div>
            </div>
            <ToastModal message={messageToast}/>
            {
                loading && <Loading  isdashPage={isdashPage}/>
            }
        </div>
    );
}

export default Services;
